import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Video from '~components/Video'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'

const ArchiveTile = ({ className, project }) => {
	const [hover, setHover] = useState(false)
	return (
		<Wrap to={resolveLink({_type: 'project', slug: project.slug})}>
			<Hover 
				className={className} 
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<Media>
					<MediaContainer>
						<StyledImage image={project?.thumbnail?.image} cover hover={hover && project?.thumbnail?.video}/>
						<StyledVideo src={project?.thumbnail?.video?.video?.asset?.url}/>
					</MediaContainer>
				</Media>
				<Title className='xs' hover={hover}>
					{project?.title}
				</Title>
			</Hover>
		</Wrap>
	)
}

const Wrap = styled(Link)`
`
const Hover = styled.div`
	
`
const Media = styled.div`
	height: 0;
	padding-bottom: 66%;
	position: relative;
	margin-bottom: 5px;
`
const MediaContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`
const Title = styled.div`
	color: var(--white);
	opacity: ${props => props.hover ? '1' : '0'};
	transition: opacity 0.25s;
`
const StyledVideo = styled(Video)`
	grid-column: span 12;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	object-fit: cover;
`
const StyledImage = styled(Image)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	opacity: ${props => props.hover ? '0' : '1'};
	transition: opacity 0.25s;
`

ArchiveTile.propTypes = {
	className: PropTypes.string,
	project: PropTypes.object,
}

export default ArchiveTile