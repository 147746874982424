import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, InsetSection } from '~styles/global'
import { graphql } from 'gatsby'
import ArchiveTile from '~components/ArchiveTile'
import DataViewer from '~utils/DataViewer'
import { useSiteState } from '~context/siteContext'
import Seo from '~components/Seo'

const Archive = ({ data }) => {
	const { page } = data
	const [siteState, setSiteState] = useSiteState()

	const projects = data?.projects?.nodes

	function lexographicalSort(a, b) {
		if (a['orderRank'] < b['orderRank']) {
			return -1
		}
		if (a['orderRank'] > b['orderRank']) {
			return 1
		}
		return 0
	}

	projects.sort(lexographicalSort)
	useEffect(() => {
		setSiteState(prevState => ({
			...prevState,
			pageTitle: page.title,
		}))
	}, [])

	return (
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Wrap>
				{projects?.map(project => (
					<ProjectTile 
						key={project} 
						project={project} 
					/>
				))}
				<DataViewer data={data} name="page"/>
			</Wrap>
		</>
	)
}

const Wrap = styled(InsetSection)`
	margin-top: 168px;
	margin-bottom: 40px;
	>div{
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 7%;
		padding-right: 110px;
		grid-row-gap: 20px;
		${tablet}{
			padding-right: 20px;
			grid-template-columns: repeat(2, 1fr);
		}
		${mobile}{
			padding-left: 20px;
		}
	}

`
const ProjectTile = styled(ArchiveTile)`

`

Archive.propTypes = {
	data: PropTypes.object
}

export const query = graphql`
	  query archiveQuery {
			page: sanityArchivePage {
				title
				seo{
					...seo
				}
			}
    	projects: allSanityProject {
				nodes {
					orderRank
					thumbnail {
						image{
							...imageWithAlt
						}
						video{
							...videoEmbed
						}
					}
					title
					slug {
						current
					}
				}
			}
		}
`

export default Archive